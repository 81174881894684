import PATH from '../config/content-path'
import { TPostSort } from '../types/type'
import { useBehaviorPatternAnalysis } from '~/modules/tracking/app/behavior-pattern-analysis'

export default function useContentNavigator () {
  const _navigate = (path: string, options?: any) => {
    const navigateOptions = {
      path,
      ...options
    }
    navigateTo(navigateOptions)
  }

  /**
   * 게시물 카테고리 목록 페이지 이동
   * @param id 카테고리 고유값
   * @param sort 정렬 기준
   */
  const navigateToCategoryPostList = (id: number, sort: TPostSort = TPostSort.POPULAR) => {
    const path = `${PATH.POST_CATEGORY_LIST}/${id}`
    _navigate(path, { query: { sort, timestamp: new Date().getTime() } })
  }

  /**
   * 게시물 인기 목록 페이지 이동
   * @param sort 정렬 기준
   */
  const navigateToPopularPostList = (sort: TPostSort = TPostSort.POPULAR) => {
    const path = `${PATH.POST_POPULAR_LIST}`
    _navigate(path, { query: { sort, timestamp: new Date().getTime() } })
  }

  /**
   * 게시물 최신 목록 페이지 이동
   * @param sort 정렬 기준
   */
  const navigateToLatestPostList = (sort: TPostSort = TPostSort.LATEST) => {
    const path = `${PATH.POST_LATEST_LIST}`
    _navigate(path, { query: { sort, timestamp: new Date().getTime() } })
  }

  /**
   * 게시물 관심사 목록 페이지 이동
   * @param sort 정렬 기준
   */
  const navigateToInterestPostList = (sort: TPostSort = TPostSort.POPULAR) => {
    const path = `${PATH.POST_INTEREST_LIST}`
    _navigate(path, { query: { sort, timestamp: new Date().getTime() } })
  }

  /**
   * 게시물 추천 목록 페이지 이동
   * @param id: 추천 고유값
   */
  const navigateToRecommendPostList = (id: number) => {
    const path = `${PATH.POST_RECOMMEND_LIST}/${id}`
    _navigate(path)
  }

  /**
   * 기업 보도자료 목록 페이지 이동
   */
  const navigateToReportPostList = () => {
    const path = `${PATH.POST_REPORT_LIST}`
    _navigate(path)
  }

  /**
   * 검색 결과 (게시물) 페이지 이동
   * @param keyword 검색 키워드
   */
  const navigateToSearchContents = (keyword: string, searchAffectType: string, sort: TPostSort = TPostSort.POPULAR) => {
    const path = `${PATH.SEARCH_CONTENTS}`
    _navigate(path, { query: { keyword, sort, search_affect_type: searchAffectType, timestamp: new Date().getTime() } })
  }

  /**
   * 검색 결과 (회사) 페이지 이동
   * @param keyword 검색 키워드
   */
  const navigateToSearchBussiness = (keyword: string, sort: TPostSort = TPostSort.POPULAR) => {
    const path = `${PATH.SEARCH_BUSINESS}`
    _navigate(path, { query: { keyword, sort, timestamp: new Date().getTime() } })
  }

  /**
   * 게시물 상세 페이지 이동
   * @param id 게시물 고유값
   */
  const navigateToPost = (id: number, options?: any) => {
    const path = `${PATH.POST_DETAIL}/${id}`
    _navigate(path, options)
  }

  /**
   * 게시물 상세 페이지 이동전 행동분석 데이터 전송
   * @param id 게시물 고유값
   */
  const navigateToPostWithAnalysis = (id: number, options?: any) => {
    const { sendContentsClickData } = useBehaviorPatternAnalysis()
    sendContentsClickData(id)

    navigateToPost(id, options)
  }

  /**
   * 관심 목록 북마크 페이지 이동
   */
  const navigateToInterestBookmark = () => {
    const path = `${PATH.INTEREST_BOOKMARK}`
    _navigate(path)
  }

  /**
   * 관심 목록 팔로잉 페이지 이동
   */
  const navigateToInterestFollowing = () => {
    const path = `${PATH.INTEREST_FOLLOWING}`
    _navigate(path)
  }

  /**
   * 팔로잉 목록 페이지 이동
   */
  const navigateToFollowList = () => {
    const path = `${PATH.FOLLOW_LIST}`
    _navigate(path)
  }

  /**
   * 회사 상세 페이지 이동
   * @param id 회사 고유값
   */
  const navigateToBusiness = (id: any, options?: any) => {
    const path = `${PATH.BUSINESS_DETAIL}/${id}`
    _navigate(path, options)
  }

  return {
    navigateToCategoryPostList,
    navigateToPopularPostList,
    navigateToLatestPostList,
    navigateToInterestPostList,
    navigateToRecommendPostList,
    navigateToReportPostList,
    navigateToSearchContents,
    navigateToSearchBussiness,
    navigateToPost,
    navigateToPostWithAnalysis,
    navigateToInterestBookmark,
    navigateToInterestFollowing,
    navigateToFollowList,
    navigateToBusiness
  }
}
