export default {
  HOME: '/',
  POST_CATEGORY_LIST: '/post/category/list',
  POST_POPULAR_LIST: '/post/popular/list',
  POST_LATEST_LIST: '/post/latest/list',
  POST_INTEREST_LIST: '/post/interest/list',
  POST_REPORT_LIST: '/post/report/list',
  POST_RECOMMEND_LIST: '/post/recommend/list',
  POST_DETAIL: '/post',
  SEARCH_CONTENTS: '/search/contents',
  SEARCH_BUSINESS: '/search/business',
  INTEREST_BOOKMARK: '/interest/bookmark',
  INTEREST_FOLLOWING: '/interest/following',
  FOLLOW_LIST: '/follow/list',
  BUSINESS_DETAIL: '/business/profile'
}
